<template>
  <!-- Ce composant permet d'afficher les alertes et avertissements d'une activité -->
  <div class="mb-0">
    <!-- Notes -->
    <h4 class="subtitle-form overload-subtitle mb-2">{{ $t('activities.details.news') }}</h4>
    <div class="max-width notes-params" v-for="alert in this.alerts" :key="alert.id">
      <div class="text-infos bg-white">
				<b-row>
					<b-col cols="2" class="text-infos bg-white" @click="showNewsModal(alert)">
						<img v-if="alert.criticity === 'A' " :src="alertModal" width="20px" height="20px"
							v-b-tooltip.hover
            	:title="$t('activities.details.see_details')"
						>
            <img v-if="alert.criticity === 'W' " :src="warningModal" width="20px" height="20px"
							v-b-tooltip.hover
            	:title="$t('activities.details.see_details')"
						>
					</b-col>
					<b-col cols="10" class="text-infos bg-white">
						<span> {{ alert.body }} </span><br />
					</b-col>
				</b-row>
      </div>
    </div>
    <hr/>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from 'vuex';
import alertModal from "@/assets/images/alert_modal.svg";
import warningModal from "@/assets/images/warning_modal.svg";

import NotificationApi from '@/api/notification.api';
import { EventBus } from '@/eventBus.js';

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      alerts: [],
			alertModal: alertModal,
      warningModal: warningModal,
    };
  },
  created(){
    this.init();
  },
  methods: {
    /**
     * Récupère la liste des alertes et avertissements liés à l'activité courante
     */
    async init(){
      if(this.currentEntry.alerts) {
				const response = await NotificationApi.getNotifications({
					campaign: this.currentCampaign.id,
					farm_id: this.currentFarm && this.currentFarm.id,
					activity: this.currentEntry.id
				});
      this.alerts = response.data;
      }
    },
    /**
     * Affiche l'information dans une boite modal
     */
		showNewsModal(alert){
			let alertToShow = [];
			alertToShow.push(alert);
			EventBus.$emit("showNewsModal", {news: alertToShow, goToDetail: () => this.hideModal(), showModifyButton: false});
		},
    /**
     * Ferme la boîte modal
     */
    hideModal() {
      EventBus.$emit("hideModal");
    }
  },
  computed: {
		...mapGetters({
      currentCampaign: 'getCampaign',
      currentFarm: 'farm/currentFarm',
    }),
	},
};
</script>

<style lang="scss" scoped>
/**
 * Text align right.
 */
.position-right {
  text-align: right;
}

/**
 * Max width of the frame.
 */
.max-width {
  width: 100%;
}

/**
 * Change font-weight to italic
 */
.font-italic {
  font-weight: italic;
}

/**
 * Change notes params.
 */
.notes-params {
  border-radius: 6px;
  padding: 0px 8px 0px 0px;
}

/**
 * Color text for empty list.
 */
.enpty-attr {
  text-align: center;
  color: $danger-color;
}
</style>
